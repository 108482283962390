<template>
    <div class="mb-2" style="color: #efefef "><small>
      last updated:
      <span v-if="timestampData === 'Loading...'">Loading <i class="fas fa-circle-notch fa-spin"></i></span>
      <span v-else>{{ timestampData }}</span>
    </small></div>
</template>

<script>
export default {
    props: {
        timestampData: String
    },
    setup(props) {

    },
}
</script>
